import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Gotenks from './../../../images/Gotenks.gif';
export const _frontmatter = {
  "path": "/Gotenks.gif.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img className="mx-auto" src={Gotenks} />
    <p>{`SSJ3 Gotenks shooting energy blasts`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      